import React from 'react';
import {Form, Tooltip, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';

import {List, DataType, getFieldsForFilter, getPageAndPerPage, Button} from '@presentation/components/form';
import Header from './header';

import schedule from '@core/modules/schedule/infra/container.registry';
import {Schedule} from '@core/modules/schedule/domain/schedule.entities';
import Status from './status';
import Modal from '@/@presentation/components/common/modal';

import { CloseOutlined } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';

const ScheduleList = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<Schedule>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setReload] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const onConfirm = () => {
        const ids = selectedItems.map((item) => item.id)
        schedule.confirm.execute(ids)
        navigate(0)
    }

    const footer: React.ReactNode[] = [
        <Button text='Não' onClick={() => setOpenModal(false)}/>,
        <Button text='Sim' onClick={onConfirm}/>,
    ]

    const onChangeRowKey = (newSelectedRowKeys: React.Key[], newSelectedRows: Schedule[]) => {
        const removedRowKeys = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));
        const removedItems = selectedItems.filter(item => removedRowKeys.includes(item.id));

        const addedRowKeys = newSelectedRowKeys.filter(key => !selectedRowKeys.includes(key));
        const addedItems = newSelectedRows.filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id));

        const updatedRowKeys = [...selectedRowKeys.filter(key => !removedRowKeys.includes(key)), ...addedRowKeys];
        const updatedItems = [...selectedItems.filter(item => !removedItems.some(removedItem => removedItem.id === item.id)), ...addedItems];
    
        setSelectedRowKeys(updatedRowKeys);
        setSelectedItems(updatedItems);
    };
    
    
    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: (newSelectedRowKeys: React.Key[], newSelectedRows: Schedule[]) => {
            onChangeRowKey(newSelectedRowKeys, newSelectedRows);
        },

        getCheckboxProps: (record: Schedule) => ({
            disabled: record.status !== 'PENDENTE' // || !record.canEdit,
        }),
    };

    async function loadData(params?: any) {
        return await schedule.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });
    }

    const rowClickProps = (record: Schedule) => {
        navigate(`/schedule/${record?.id}`, {
            state: {canEdit: record?.canEdit},
        });
    }

    const removeItem = (id: string) => {
        const items = selectedItems.filter(item => item.id !== id);
        setSelectedItems(items);

        const keys = selectedRowKeys.filter(key => key !== id);
        setSelectedRowKeys(keys);
    }

    const selectedView = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: 10 }}>
                <span style={{ color: '#1890ff', fontSize: 14, fontWeight: 'bold', marginBottom: 15, marginLeft: 10 }}>
                    {selectedItems.length} item(s) selecionado(s)
                </span>
                <div>
                    {selectedItems.map((item, index) => (
                        <span key={index} style={{
                            backgroundColor: '#1890ff',
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: '#fff',
                            padding: 5,
                            borderRadius: 5,
                            margin: 5,
                            marginTop: 10,
                        }}>
                            {item.id} <CloseOutlined
                                onClick={() => removeItem(item.id)}
                                style={{
                                    cursor: 'pointer',
                                }} />
                        </span>
                    ))}
                </div>
            </div>
        )

    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID ',
            dataIndex: 'id',
            width: 80,
            align: 'center',
            render: (id) => (
                <a>{id}</a>
            ),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (e) => {
                        e.stopPropagation();
                        rowClickProps(record as unknown as Schedule);
                    },
                };
            },
        },
        {
            title: 'Data Procedimento ',
            dataIndex: 'procedureDate',
            sortDirections: ['descend'],
            width: 120,
            align: 'center',
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Unidade',
            dataIndex: 'unit',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Cirurgião',
            dataIndex: 'surgeon',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (surgeon) => (
                <Tooltip placement="topLeft" title={surgeon}>
                    {surgeon?.length > 23 ? `${surgeon.substring(0, 23)}...` : surgeon}
                </Tooltip>
            ),
        },
        {
            title: 'Procedimento',
            dataIndex: 'procedure',
            defaultSortOrder: 'descend',
            render: (procedure) => (
                <Tooltip placement="topLeft" title={procedure}>
                    {procedure?.length > 26 ? `${procedure.substring(0, 26)}...` : procedure}
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            defaultSortOrder: 'descend',
            width: 100,
            render: (record) => (
                <Status key={record?.id} status={record} />
            ),
            responsive: ['sm'],
        },
    ]

    return (
        <>
        <Modal
            footer={footer}
            open={openModal}
            title='Confirme'
        >
            <Typography>Tem certeza que deseja confirmar todos os agendamentos selecionados?</Typography>
        </Modal>
            <List
                selectedKeyView={selectedView}
                rowSelection={rowSelection}
                columns={columns}
                loadData={loadData}
                Form={Form}
                setLoading={setLoading}
                loading={loading}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setParams={setParams}
                params={params}
                setReload={setReload}
                isReload={isReload}
                headerChildren={<Header />}
                showStatusFilter
            />
            <div style={{ padding: 10 }}>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        nativeProps={{ style: { marginRight: -10 } }}
                        type='primary'
                        text='Confirmar procedimento'
                        disabled={selectedItems.length === 0}
                        onClick={() => setOpenModal(true)}
                    />
                </div>
            </div>
        </>
    );
};

export default ScheduleList;
